<template>
  <div>
    <Pane />
    <a-card class="container">
      <div class="title">
        <span style="padding-right: 4px">常用审批</span>
        <a-tooltip title="清空">
          <a-icon type="delete" class="delete" @click="deleteOftenUse" />
        </a-tooltip>
      </div>
      <div class="content">
        <div class="item" v-for="(item, index) in oftenUseList" :key="index" @click="route(item)">
          <div style="text-decoration: underline;cursor: pointer;">{{ item.taskName }}</div>
        </div>
      </div>

      <!-- <Clock /> -->
      <div v-for="(item, index) in catagory" :key="index">
        <div v-if="item.is_top">
          <div class="title">{{ item.taskName}}</div>
          <div class="content">
            <div class="item" v-for="(item, index) in item.children" :key="index" @click.stop="route(item)">
              <div v-if="item.is_top">
                <div :class="item.children ? 'subTitle' : ''" :style="{'text-decoration': item.children?'none':'underline','cursor': item.children?'auto':'pointer'}">{{ item.taskName}}</div>
                <div class="content">
                  <div class="item" style="text-decoration: underline;cursor: pointer;" v-for="(item, index) in item.children" :key="index" @click.stop="route(item)">
                    <div v-if="item.is_top">{{ item.taskName }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="title">综合办公</div>
      <div class="content">
        <div class="item" v-for="(item, index) in filteredOA" :key="index" @click="route(item)">
          <div>{{ item.title }}</div>
        </div>
      </div>

      <div class="title">生产管理</div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in filteredProduce"
          :key="index"
          @click="route(item)"
        >
          <div>{{ item.title }}</div>
        </div>
      </div>

      <div class="title">财务管理</div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in filteredFinance"
          :key="index"
          @click="route(item)"
        >
          <div>{{ item.title }}</div>
        </div>
      </div> -->
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
// import Clock from "./clock.vue";
import request from "@/api/request";
function getCatagory() {
    return request({
        url: "/common-service/sysreview/category/all",
    });
}
export default {
  // components: {
  //   Clock
  // },

  mixins: [watermark],

  data() {
    return {
      catagory: [],
      oa: [
        {
          title: "酒水申领审批表",
          path: "/oa/material/apply"
        },
        {
          title: "会议室申请审批表",
          path: "/oa/meeting/add"
        },

        {
          title: "任务督办单",
          path: "/oa/supervise/add"
        },

        {
          title: "档案移交审批表",
          path: "/oa/archive/query/transfer"
        },

        {
          title: "档案出借(检索)",
          path: "/oa/archive/query"
        },
        {
          title: "临时餐卡借用申请",
          path: "/oa/meal-card/apply"
        }
      ],
      produce: [
        {
          title: "合同金额变更申请审批",
          path: "/produce/contract-adjust/add"
        },
        {
          title: "收入合同审批",
          path: "/produce/contractRegister/review/add"
        },
        {
          title: "外部采购类合同审批",
          path: "/produce/contractRegister/subpackage/add"
        },
        {
          title: "框架类合同审批",
          path: "/produce/contractRegister/framework/add"
        }
      ],
      finance: [
        {
          title: "开票换票退票审批表",
          path: "/finance/invoice/apply"
        }
      ],

      oftenUseList: []
    };
  },

  computed: {
    filteredOA() {
      return this.oa.filter(
        item => item.path && this.$getPermission(item.path)
      );
    },
    filteredProduce() {
      return this.produce.filter(
        item => item.path && this.$getPermission(item.path)
      );
    },
    filteredFinance() {
      return this.finance.filter(
        item => item.path && this.$getPermission(item.path)
      );
    }
  },

  mounted() {
    const key = "often-use-approval";
    const str = window.localStorage.getItem(key);
    if (str) {
      const list = JSON.parse(str);
      list.sort((x, y) => {
        if (x.count > y.count) {
          return -1;
        } else if (x.count < y.count) {
          return 1;
        } else {
          return 0;
        }
      });
      this.oftenUseList = list.filter((item, index) => index < 10);
    }
    getCatagory().then(res => {
      this.catagory = res;
    });
  },

  methods: {
    route(item) {
      const key = "often-use-approval";
      const str = window.localStorage.getItem(key);
      if (str) {
        const list = JSON.parse(str);

        let index = list.findIndex(element => element.path === item.path);
        const newItem = list[index];
        if (newItem) {
          newItem.count++;
          list.splice(index, 1, newItem);
          console.log("count++");
        } else {
          const obj = {
            ...item,
            count: 1
          };
          list.push(obj);
        }
        window.localStorage.setItem(key, JSON.stringify(list));
      } else {
        const list = [
          {
            ...item,
            count: 1
          }
        ];
        window.localStorage.setItem(key, JSON.stringify(list));
      }

      this.$router.push(item.path);
    },

    deleteOftenUse() {
      this.oftenUseList = [];
      const key = "often-use-approval";
      window.localStorage.removeItem(key);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 12px;
}
.subTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  position: relative;
  line-height: 1em;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
  position: relative;
  left: 12px;
  line-height: 1em;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 4px;
    background-color: #1890ff;
  }
}

.delete {
  cursor: pointer;
  font-size: 12px;
}

.content {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-right: 16px;
  font-size: 14px;
}
</style>